/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.selectpicker').selectpicker({style: 'btn-default',size: 4});
        setTimeout(function() {$('.selectpicker').selectpicker({style: 'btn-default',size: 4});}, 500);

        $(window).on("load scroll", function() {
          if($('section.about-methods').length) {
            var bottom_of_window = $(window).scrollTop() + ($(window).height() / 1.3);
            var bottom_of_object = $('section.about-methods').offset().top;
            var element = $('section.about-methods');

            if (bottom_of_window > bottom_of_object) {
              $('section.about-methods').addClass('animation');
            }
          }
        });

        var $myGroup = $('header');
        $myGroup.on('show.bs.collapse','.collapse', function() {
            $myGroup.find('.collapse.in').collapse('hide');
        });

        $('section.single-product .current_page_item').each(function() {
          $(this).parent().addClass('in');
          $('button[data-target="#'+$(this).parent().attr('id')+'"]').attr('aria-expanded', 'true');
        });

        $('#other-references').owlCarousel({
          stagePadding: 50,
          loop: false,
          center: false,
          margin: 1,
          nav: false,
          items: 1,
          responsive: {
            768: {
              items: 2
            },
            1025: {
              items: 3
            },
            1200: {
              items: 4,
              stagePadding: 0
            },
          }
        });

        $('#reference-slider').owlCarousel({
          stagePadding: 0,
          loop: true,
          center: true,
          margin: 0,
          nav: true,
          items: 1,
          responsive: {
            768: {
              stagePadding: 100,
              margin: 30
            },
            1025: {
              stagePadding: 200,
              margin: 30
            },
            1200: {
              stagePadding: 350,
              margin: 30
            },
            1440: {
              stagePadding: 500,
              margin: 30
            },
          }
        });

        $('.owl-modal').owlCarousel({
          loop: true,
          margin: 0,
          nav: false,
          items: 1
        });

        $('#news').owlCarousel({
          loop: false,
          margin: 0,
          nav: false,
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 2
            },
            1025: {
              items: 4,
              stagePadding: 15
            }
          }
        });

        function createMoreMenu(menu, width) {
          if($(window).width() > width) { //get body width without(!) scrollbar
            var w = 0;
            var mw = $(menu).parent().width() - $('#lang_sel').width() - 62; //80 = width of 'more' item
            var menuhtml = '';
            $(menu).children().each(function() {
              w += $(this).outerWidth(true);
              if (mw < w && !$(this).find('a').hasClass('more')) {
                menuhtml += $('<div>').append($(this).clone()).html();
                $(this).remove();
              }
            });
            if(menuhtml) {
              $(menu + " .dropdown.more>ul").html('');
              $(menu + " .dropdown.more>ul").append(menuhtml);
              $(menu + " .dropdown.more").removeClass('hidden');
            }
          }
        }

        $('header ul.more').each(function() {
          var menu = '#' + $(this).attr('id');
          var width = $(this).data('breakpoint') || 1025;
          var originalMenu = $(menu).clone();
          createMoreMenu(menu, width); $(menu).removeClass('vh'); $(originalMenu).removeClass('vh');
          $(window).on("load", function() { createMoreMenu(menu, width); $(menu).removeClass('vh'); $(originalMenu).removeClass('vh'); });
          $(window).on("resize", function() { $(menu).replaceWith(originalMenu.clone()); createMoreMenu(menu, width); });
        });

        $(".nav-toggle").click(function(event) {
            var scrollHeight = $(window).scrollTop();
            $(".nav-wrapper, .nav-toggle, html, body").toggleClass("open");
            $('.locations-toggle, .search-toggle').collapse("hide");
            event.preventDefault();
            if($('.nav-toggle').hasClass('open')) {
              $('.nav-toggle').attr('data-height', scrollHeight);
            } else {
              $(window).scrollTop($('.nav-toggle').attr('data-height'));
            }
        });
        $('.locations-toggle, .search-toggle').click(function() {
          $(".nav-wrapper, .nav-toggle, html, body").removeClass("open");
        });
        $('.search-toggle').click(function() {
          $('input[name="s"]').focus();
          setTimeout(function() {
            $('input[name="s"]').focus();
          }, 500);
        });

        $('.sh-methods').matchHeight();
        $('.sh-title').matchHeight();
        $('.sh-content').matchHeight();

        $(".lang_sel_sel").click(function(event) {
          event.preventDefault();
          $("#lang_sel").toggleClass("hover");
        });

        function scrollTo(hash) {
          $('h1, h2, h3').each(function() {
            var mystring = $(this);
            if($(this).text() && $(this).text().toLowerCase() !== '' && hash == '#'+$(this).text().toLowerCase().trim().replace('_','').replace(/\s/g,'_')) {
              $('html,body').animate({
                scrollTop: mystring.offset().top - 100
              }, 600);
              return false;
            }
          });
        }
        $('a[href*="#"]').click(function() {
          if(this.hash !== "" && this.hash.split('#')[0] === window.location.href) {
            event.preventDefault();
            scrollTo(this.hash);
          } else if(this.hash !== "") {
            window.location.href = this.hash;
          }
        });
        $(window).on('load', function() {
          if(window.location.hash) {
            scrollTo(window.location.hash);
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('svg g').on('mouseover', function() {
          if($(this).data('title')) {
            $('#hovertitle').text($(this).data('title'));
            var left = ($('#hovertitle').outerWidth() / 2);
            $('#hovertitle').offset({ top: $(this).offset().top - $('#hovertitle').outerHeight() - 10, left: $(this).offset().left + 25 - left });
            $('#hovertitle').addClass('open');
          }
        });
        $('svg g').on('mouseout', function() {
          $('#hovertitle').removeClass('open');
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
